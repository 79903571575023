import * as React from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import styles from './Navigation.module.scss';
import projects from '../../data/projects';
import { MODE } from '../App';

interface Props {
	activePageIndex: number;
	setActivePageIndex: (index: number) => void;
	isTouch: boolean;
	mode: MODE;
	isLoaded: boolean;
}

const Navigation = ({ activePageIndex, isTouch, isLoaded, mode }: Props) => (
	<div className={cx(styles.sidebar, mode === MODE.DARK && styles.dark, isTouch && styles.touch, isLoaded && styles.loaded)}>
		<span className={styles.title}>Anneka Goss</span>
		<ul className={styles.navigation}>
			<Link to='/about' className={cx(styles.mainNavItem, activePageIndex === 0 && styles.active)}>
				About
			</Link>
			{projects.map((project, i) => (
				<Link className={cx(styles.navItem, activePageIndex === i + 1 && styles.active)} to={`/${project.slug}`} key={i + 1}>
					{project.title}
				</Link>
			))}
		</ul>
	</div>
);

export default Navigation;
