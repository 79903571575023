import * as React from 'react';
import cx from 'classnames';
import styles from './loader.module.scss';
import { MODE } from '../App';

interface Props {
	mode: MODE;
	isLoaded: Boolean;
}

const Loader = ({ mode, isLoaded }: Props) => (
	<div className={cx(styles.loaderOverlay, mode === MODE.DARK && styles.dark, isLoaded && styles.loaded)}>
		<div className={styles.loader} />
	</div>
);

export default Loader;
