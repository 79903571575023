import * as React from 'react';
import about from '../../data/about';
import {MODE} from '../App';
import sangbleuKingdom from '../../assets/fonts/sangbleuKingdom';
import univers from '../../assets/fonts/univers';

interface Props {
	items: any[];
	mode: MODE;
}

enum BREAKPOINT {
	XSMALL,
	SMALL,
	MEDIUM,
	LARGE,
}

interface ItemProps {
	breakpoint: BREAKPOINT;
	textColor: string;
}

interface ProjectProps extends ItemProps {
	item: Record<string, any>;
}

const HEIGHT_THRESHOLD = 870;
const WIDTH_THRESHOLD = 1440;
const SMALL_THRESHOLD = 900;
const XSMALL_THRESHOLD = 320;

const setMaxWidth = (breakpoint: BREAKPOINT) => {
	switch (breakpoint) {
		case BREAKPOINT.LARGE:
			return 1000;
		case BREAKPOINT.MEDIUM:
			return window.innerWidth - 500;
		default:
			return '100%';
	}
};

const setMargin = (breakpoint) => {
	if ([BREAKPOINT.SMALL, BREAKPOINT.XSMALL].includes(breakpoint)) {
		return 20;
	}
	return Math.max(window.innerWidth * 0.28, 300);
};

const setTopMargin = (min: number, breakpoint: BREAKPOINT): number => {
	if (breakpoint === BREAKPOINT.LARGE) return Math.max(window.innerHeight * 0.25, min);
	return min;
};

const setTitleSize = (breakpoint: BREAKPOINT): number => {
	switch (breakpoint) {
		case BREAKPOINT.LARGE:
			return 60;
		case BREAKPOINT.MEDIUM:
			return 50;
		case BREAKPOINT.SMALL:
			return 24;
		case BREAKPOINT.XSMALL:
			return 21;
	}
};

const setTextSize = (breakpoint: BREAKPOINT): number => {
	switch (breakpoint) {
		case BREAKPOINT.LARGE:
			return 21;
		case BREAKPOINT.MEDIUM:
			return 16;
		case BREAKPOINT.SMALL:
			return 14;
		case BREAKPOINT.XSMALL:
			return 12;
	}
};

const setVerticalAlign = (breakpoint: BREAKPOINT): string =>
	[BREAKPOINT.SMALL, BREAKPOINT.XSMALL].includes(breakpoint) ? 'middle' : 'top';

const setHeaderMargin = (breakpoint: BREAKPOINT): number =>
	[BREAKPOINT.SMALL, BREAKPOINT.XSMALL].includes(breakpoint) ? 10 : 20;

const baseInnerGroupStyle = {
	display: 'inline-block',
	width: '99%',
};

const baseTitleStyle = {
	fontFamily: 'SangBleu Kingdom',
	fontWeight: 500,
	letterSpacing: -0.25,
	marginBottom: 15,
};

const baseTextStyle = {
	fontFamily: 'Univers LT Std',
	fontWeight: 500,
};

const spacerStyle = {
	height: '100%',
	width: 1,
	verticalAlign: 'middle',
	display: 'inline-block',
};

const baseHeaderStyle = {
	...baseTextStyle,
	fontSize: 14,
	textTransform: 'uppercase',
	letterSpacing: 0.2,
};

const About = ({breakpoint, textColor}: ItemProps) => {
	const margin = setMargin(breakpoint);
	const contentGroupStyle = {
		height: window.innerHeight,
		marginLeft: margin,
		marginRight: [BREAKPOINT.SMALL, BREAKPOINT.XSMALL].includes(breakpoint) ? margin : 0,
	};

	const innerGroupStyle = {
		...baseInnerGroupStyle,
		marginTop: setTopMargin(
			[BREAKPOINT.SMALL, BREAKPOINT.XSMALL].includes(breakpoint) ? 250 : 152,
			breakpoint
		),
		maxWidth: setMaxWidth(breakpoint),
		verticalAlign: setVerticalAlign(breakpoint),
	};

	const headerStyle = {
		...baseHeaderStyle,
		marginBottom: setHeaderMargin(breakpoint),
	};

	const titleStyle = {
		...baseTitleStyle,
		fontSize: setTitleSize(breakpoint),
		lineHeight: 1.25,
		color: textColor,
	};

	const textStyle = {
		...baseTextStyle,
		marginBottom: 5,
		fontSize: setTextSize(breakpoint),
		color: textColor,
	};

	const linkStyle = {
		...baseTextStyle,
		lineHeight: 1.44,
		maxWidth: 480,
		letterSpacing: 0.23,
		textTransform: 'uppercase',
		marginRight: 10,
		fontSize: 14,
		color: textColor,
		textDecoration: 'underline',
		display: 'inline-block',
	};

	const smallTextStyle = {
		...textStyle,
		marginTop: [BREAKPOINT.SMALL, BREAKPOINT.XSMALL].includes(breakpoint) ? 10 : 20,
	};

	return (
		<div style={contentGroupStyle}>
			<div style={innerGroupStyle}>
				<div style={titleStyle}>{about.title}</div>
				<div style={textStyle}>{about.email}</div>
				<a style={linkStyle} href={about.twitter}>
					TWITTER
				</a>
				<a style={linkStyle} href={about.instagram}>
					INSTAGRAM
				</a>
				<a style={linkStyle} href={about.github}>
					GITHUB
				</a>
				<a style={linkStyle} href={about.medium}>
					MEDIUM
				</a>
				<a style={linkStyle} href={about.vimeo}>
					VIMEO
				</a>
				<a style={linkStyle} href={about.behance}>
					BEHANCE
				</a>
				<div style={smallTextStyle}>{about.text}</div>
			</div>
			<div style={spacerStyle} />
		</div>
	);
};

const Project = ({item, breakpoint, textColor}: ProjectProps) => {
	const margin = setMargin(breakpoint);

	const contentGroupStyle = {
		height: window.innerHeight,
		marginLeft: margin,
		marginRight: [BREAKPOINT.SMALL, BREAKPOINT.XSMALL].includes(breakpoint) ? margin : 0,
	};

	const innerGroupStyle = {
		...baseInnerGroupStyle,
		marginTop: setTopMargin(
			[BREAKPOINT.SMALL, BREAKPOINT.XSMALL].includes(breakpoint) ? 250 : 186,
			breakpoint
		),
		maxWidth: setMaxWidth(breakpoint),
		verticalAlign: setVerticalAlign(breakpoint),
	};

	const headerStyle = {
		...baseHeaderStyle,
		marginBottom: setHeaderMargin(breakpoint),
		fontSize: breakpoint === BREAKPOINT.XSMALL ? 12 : 14,
	};

	const titleStyle = {
		...baseTitleStyle,
		fontSize: setTitleSize(breakpoint),
		lineHeight: 1.125,
		color: textColor,
	};

	const textStyle = {
		...baseTextStyle,
		fontSize: setTextSize(breakpoint),
		color: textColor,
		marginBottom: [BREAKPOINT.SMALL, BREAKPOINT.XSMALL].includes(breakpoint) ? 10 : 20,
		lineHeight: 1.44,
		maxWidth: breakpoint === BREAKPOINT.LARGE ? 700 : 480,
		letterSpacing: 0.23,
	};

	const linkStyle = {
		...baseTextStyle,
		textTransform: 'uppercase',
		textDecoration: 'underline',
	};

	return (
		<div style={contentGroupStyle}>
			<div style={innerGroupStyle}>
				<div style={headerStyle}>
					<span style={{paddingRight: 6}}>{item.header}</span>/
					<span style={{paddingLeft: 6}}>{item.client}</span>
				</div>
				<div style={titleStyle}>{item.title}</div>
				<div style={textStyle}>{item.text}</div>
				<a style={linkStyle} href={item.link && item.link.href}>
					{item.link && item.link.text}
				</a>
			</div>
			<div style={spacerStyle} />
		</div>
	);
};

const readBreakpoint = () => {
	if (window.innerWidth > WIDTH_THRESHOLD || window.innerHeight > HEIGHT_THRESHOLD) {
		return BREAKPOINT.LARGE;
	}
	if (window.innerWidth > SMALL_THRESHOLD) return BREAKPOINT.MEDIUM;
	if (window.innerWidth > XSMALL_THRESHOLD) return BREAKPOINT.SMALL;
	return BREAKPOINT.XSMALL;
};

const Content = ({items, mode}: Props) => {
	const [breakpoint, setBreakpoint] = React.useState<BREAKPOINT>(readBreakpoint());

	const updateBreakpoint = () => {
		setBreakpoint(readBreakpoint());
	};

	React.useEffect(() => {
		window.addEventListener('resize', updateBreakpoint);
		return () => {
			window.removeEventListener('resize', updateBreakpoint);
		};
	}, []);

	const textColor = mode === MODE.LIGHT ? 'black' : 'white';

	return (
		<div>
			<About breakpoint={breakpoint} textColor={textColor} />
			{items.map((item, i) => (
				<Project key={i} breakpoint={breakpoint} item={item} textColor={textColor} />
			))}
		</div>
	);
};

export default Content;
