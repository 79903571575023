const ITEMS = [
	{
		header: '2020',
		title: 'WebGL Shader Demos',
		text:
			'A collection of interactive shader examples using native WebGL and React hooks. References to help web developers learn graphics programming.',
		client: 'Personal',
		link: {
			text: 'Visit',
			href: 'https://annekagoss.github.io/shader-demos.github.io/',
		},
		slug: 'webgl-shader-demos',
	},
	{
		header: '2019 - Ongoing',
		title: 'Geometric Primitive',
		text:
			'An arts practice and research studio that combines A/V installations, 3D graphics, synthesizers, lasers, and sculpture. Past work includes Oscillozoic with Marfa Open and site-specific laser installations with the Brahman.ai experimental arts group.',
		client: 'Personal',
		link: {
			text: 'Visit',
			href: 'https://www.instagram.com/geometricprimitive',
		},
		slug: 'geometric-primitive',
	},
	{
		header: '2019',
		title: 'Oscillozoic',
		text:
			'An immersive installation exhibited at the 2019 Marfa Open Arts Festival that transformed an overgrown concrete structure into a lost ritual site of volumetric laser sculpture and multi-channel sound.',
		client: 'Marfa Open',
		link: {
			text: 'Visit',
			href: 'https://www.behance.net/gallery/95537581/Oscillozoic',
		},
		slug: 'oscillozoic',
	},
	{
		header: '2018',
		title: 'Splash Intro',
		text:
			"Interactive website splash page illustrating the client's ability to transform chaos into clear narratives through investigative data analysis.",
		client: 'Mintz Group',
		link: {
			text: 'Visit',
			href: 'https://mintzgroup.com/',
		},
		slug: 'splash-intro',
	},
	{
		header: '2016',
		title: 'Gravitational Waves Visualized',
		text:
			'The first detected gravitational wave visualized using various 3D intepretations of Minkowski spacetime.',
		client: 'Thesis work, NYU Tandon Integrated Digital Media',
		link: {
			text: 'Visit',
			href: 'http://www.gwave.annekagoss.com/',
		},
		slug: 'gravitational-waves',
	},
	{
		header: '2015',
		title: "Worker's Rights in the Bangladesh Garment Industry",
		text:
			'Data visualization showing the regulatory status of thousands of factories in relation to production capacity and number of workers.',
		client: 'NYU Stern Center for Human Rights',
		link: {
			text: 'Visit',
			href: 'http://people.stern.nyu.edu/twadhwa/bangladesh/index.html',
		},
		slug: 'bangladesh-garment-workers',
	},
	{
		header: '2015',
		title: 'Submerge',
		text:
			'Installation and web visualization that programmatically translates global warming data into a virtual ocean and sky.',
		client: 'NYC Media Lab Summit',
		link: {
			text: 'Visit',
			href: 'http://submerge.annekagoss.com/',
		},
		slug: 'submerge',
	},
];

export default ITEMS;
