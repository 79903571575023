import * as React from 'react';
import cx from 'classnames';
import initialBaseVertexShader from '../../../lib/gl/shaders/base.vert';
import initialWaterFragmentShader from '../../../lib/gl/shaders/water.frag';
import { BASE_UNIFORMS } from '../../utils/general';
import { UNIFORM_TYPE, UniformSettings } from '../../../types';
import WaterCanvas from './WaterCanvas';
import WaterSource from './WaterSource';
import styles from './WaterCanvas.module.scss';
import { MODE } from '../App';
import { useScrollJack } from '../../hooks/scrollJack';

const BASE_WATER_UNIFORMS: UniformSettings = {
	...BASE_UNIFORMS,
	uTime: {
		defaultValue: 0,
		name: 'uTime',
		readonly: true,
		type: UNIFORM_TYPE.FLOAT_1,
		value: 0,
	},
	uSamplerResolution0: {
		defaultValue: { x: 0, y: 0 },
		name: 'uSamplerResolution0',
		readonly: true,
		type: UNIFORM_TYPE.VEC_2,
		value: { x: 0, y: 0 },
	},
	uScroll: {
		defaultValue: { x: 0, y: 0 },
		name: 'uScroll',
		readonly: true,
		type: UNIFORM_TYPE.VEC_2,
		value: { x: 0, y: 0 },
	},
	uNumItems: {
		defaultValue: 0,
		name: 'uNumItems',
		readonly: true,
		type: UNIFORM_TYPE.FLOAT_1,
		value: 0,
	},
	uMode: {
		defaultValue: 0,
		name: 'uMode',
		readonly: false,
		type: UNIFORM_TYPE.INT_1,
		value: 0,
	},
};

interface Props {
	activePageIndex: number;
	setActivePageIndex: (index: number) => void;
	children: React.ReactNode;
	numItems: number;
	mode: MODE;
	isTouch: Boolean;
	isLoaded: Boolean;
}

const Water = ({ children, numItems, activePageIndex, setActivePageIndex, mode, isTouch, isLoaded }: Props) => {
	const [attributes, setAttributes] = React.useState<any[]>([]);
	const baseUniforms = React.useRef<UniformSettings>(BASE_WATER_UNIFORMS);
	const [waterFragmentShader, setBaseFragmentShader] = React.useState<string>(initialWaterFragmentShader);
	const [waterFragmentError, setWaterFragmentError] = React.useState<Error | null>();
	const [baseVertexShader, setBaseVertexShader] = React.useState<string>(initialBaseVertexShader);
	const [baseVertexError, setBaseVertexError] = React.useState<Error | null>();
	baseUniforms.current.uNumItems.value = numItems;

	if (!isTouch)
		return (
			<div className={cx(styles.container, isLoaded && styles.loaded)}>
				<WaterCanvas
					fragmentShader={waterFragmentShader}
					vertexShader={baseVertexShader}
					uniforms={baseUniforms}
					setAttributes={setAttributes}
					setFragmentError={setWaterFragmentError}
					setVertexError={setBaseVertexError}
					foregroundContent={children}
					numItems={numItems}
					activePageIndex={activePageIndex}
					setActivePageIndex={setActivePageIndex}
					mode={mode}
				/>
			</div>
		);

	useScrollJack(
		numItems,
		activePageIndex,
		() => {
			// scrollPosRef.current.y = 1 / 24 + (window.scrollY / sourceElementRef.current.clientHeight) * 1.01;
		},
		(nextIndex: number) => {
			setActivePageIndex(nextIndex);
		}
	);

	return (
		<div className={cx(styles.container, isLoaded && styles.loaded)}>
			<WaterSource content={children} />
		</div>
	);
};

export default Water;
