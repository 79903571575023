import * as React from 'react';
import cx from 'classnames';
import { UniformSettings, Vector2, MESH_TYPE, LoadedShaders } from '../../../types';
import { assignUniforms, InitializeProps } from '../../../lib/gl/initialize';
import { BASE_TRIANGLE_MESH } from '../../../lib/gl/settings';
import { useAnimationFrame } from '../../hooks/animation';
import { useWindowSize } from '../../hooks/resize';
import { useMouse } from '../../hooks/mouse';
import { useRasterizeToGL } from '../../hooks/rasterize';
import { useUpdateShaders } from '../../hooks/updateShaders';
import WaterSource from './WaterSource';
import { useScrollJack } from '../../hooks/scrollJack';
import styles from './WaterCanvas.module.scss';
import { MODE } from '../App';

interface Props {
	fragmentShader: string;
	vertexShader: string;
	uniforms: React.MutableRefObject<UniformSettings>;
	setAttributes: (attributes: any[]) => void;
	textureSource?: string;
	setFragmentError: (error: Error | null) => void;
	setVertexError: (error: Error | null) => void;
	foregroundContent: React.ReactNode;
	numItems: number;
	activePageIndex: number;
	setActivePageIndex: (index: number) => void;
	mode: MODE;
}

interface RenderProps {
	gl: WebGLRenderingContext;
	uniformLocations: Record<string, WebGLUniformLocation>;
	uniforms: UniformSettings;
	time: number;
	mousePos: Vector2;
	scrollPos: Vector2;
	texture?: HTMLImageElement;
}

const render = ({ gl, uniformLocations, uniforms, time, mousePos, scrollPos }: RenderProps) => {
	if (!gl) return;
	assignUniforms(uniforms, uniformLocations, gl, time, mousePos, scrollPos);
	gl.activeTexture(gl.TEXTURE0);
	gl.drawArrays(gl.TRIANGLE_STRIP, 0, 4);
};

const WaterCanvas = ({ fragmentShader, vertexShader, uniforms, setAttributes, setFragmentError, setVertexError, foregroundContent, numItems, activePageIndex, setActivePageIndex, mode }: Props) => {
	const canvasRef: React.RefObject<HTMLCanvasElement> = React.useRef<HTMLCanvasElement>();
	const size: React.MutableRefObject<Vector2> = React.useRef<Vector2>({
		x: uniforms.current.uResolution.value.x,
		y: uniforms.current.uResolution.value.y,
	});
	const initialMousePosition = uniforms.current.uMouse ? uniforms.current.uMouse.defaultValue : { x: 0.5, y: 0.5 };
	const mousePosRef: React.MutableRefObject<Vector2> = React.useRef<Vector2>({
		x: size.current.x * initialMousePosition.x,
		y: size.current.y * -initialMousePosition.y,
	});
	const scrollPosRef: React.MutableRefObject<Vector2> = React.useRef<Vector2>({ x: 0.0, y: 0.0 });
	const gl = React.useRef<WebGLRenderingContext>();
	const programRef: React.MutableRefObject<WebGLProgram> = React.useRef<WebGLProgram>();
	const loadedShadersRef: React.MutableRefObject<LoadedShaders> = React.useRef<LoadedShaders>({ fragmentShader: null, vertexShader: null });
	const uniformLocations = React.useRef<Record<string, WebGLUniformLocation>>();
	const imageTexturesRef: React.MutableRefObject<Record<string, string>> = React.useRef<Record<string, string>>({});
	const texturesRef: React.MutableRefObject<WebGLTexture[]> = React.useRef<WebGLTexture[]>([]);
	const sourceElementRef: React.RefObject<HTMLDivElement> = React.useRef<HTMLDivElement>();
	const cursorElementRef: React.RefObject<HTMLImageElement> = React.useRef<HTMLImageElement>();
	const allowMotion: boolean = window.matchMedia('(prefers-reduced-motion: no-preference)').matches;
	if (!allowMotion)
		return (
			<div className={cx(styles.noMotion, styles.fullScreenCanvas)}>
				<WaterSource
					ref={{
						sourceRef: sourceElementRef,
						cursorRef: cursorElementRef,
					}}
					content={foregroundContent}
				/>
			</div>
		);

	const initializeGLProps: InitializeProps = {
		gl,
		uniformLocations,
		canvasRef,
		fragmentSource: fragmentShader,
		vertexSource: vertexShader,
		programRef,
		loadedShadersRef,
		uniforms: uniforms.current,
		size,
		meshType: MESH_TYPE.BASE_TRIANGLES,
		imageTextures: imageTexturesRef.current,
		texturesRef,
	};
	useRasterizeToGL({
		sourceElementRef,
		cursorElementRef,
		imageTexturesRef,
		initializeGLProps,
		useDevicePixelRatio: false,
		reloadOnInteraction: false,
		reloadOnChange: [mode],
	});

	React.useEffect(() => {
		setAttributes([{ name: 'aVertexPosition', value: BASE_TRIANGLE_MESH.join(', ') }]);
		scrollPosRef.current.y = 1 / 24;
	}, []);

	React.useEffect(() => {
		uniforms.current.uMode.value = mode === MODE.LIGHT ? 0 : 1;
	}, [mode]);

	useUpdateShaders({
		gl,
		programRef,
		loadedShadersRef,
		uniformLocations,
		uniforms,
		fragmentShader,
		vertexShader,
		setFragmentError,
		setVertexError,
	});
	useWindowSize(canvasRef, gl, uniforms.current, size, false);

	useScrollJack(
		numItems,
		activePageIndex,
		() => {
			const offset = window.innerWidth > 900 ? 1 / 24 : 1 / 12;
			scrollPosRef.current.y = offset + (window.scrollY / sourceElementRef.current.clientHeight) * 1.01;
		},
		(nextIndex: number) => {
			setActivePageIndex(nextIndex);
		}
	);

	useAnimationFrame(canvasRef, (time: number) => {
		render({
			gl: gl.current,
			uniformLocations: uniformLocations.current,
			uniforms: uniforms.current,
			time,
			mousePos: mousePosRef.current,
			scrollPos: scrollPosRef.current,
		});
	});

	return (
		<div className={styles.canvasContainer}>
			<canvas ref={canvasRef} className={styles.fullScreenCanvas} width={size.current.x} height={size.current.y} aria-label='DOM rasterization canvas' role='img' />
			<WaterSource
				ref={{
					sourceRef: sourceElementRef,
					cursorRef: cursorElementRef,
				}}
				content={foregroundContent}
			/>
		</div>
	);
};

export default WaterCanvas;
