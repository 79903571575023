import * as React from 'react';

import { useHistory } from 'react-router-dom';
import cx from 'classnames';
import { glSupported } from '../utils/general';
import Water from './Water/Water';
import Content from './Content/Content';
import Loader from './Loader/Loader';
import styles from './app.module.scss';
import projects from '../data/projects';
import Navigation from './Navigation/Navigation';
import './fonts.scss';

interface Props {
	section?: string;
}

export enum MODE {
	LIGHT,
	DARK,
}

const isTouch: boolean = Boolean('ontouchstart' in window);

const indexForSection = (section: string): number => {
	if (['section', ''].includes(section)) return 0;
	return projects.findIndex((project) => project.slug === section) + 1;
};

const slugForIndex = (index: number): string => {
	if (index === 0) return 'about';
	const project = projects[index - 1];
	return project ? project.slug : '';
};

const prefersDarkMode = () => window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;

const App = ({ section = '' }: Props) => {
	const [activePageIndex, setActivePageIndex] = React.useState<number>(indexForSection(section));
	const [isLoaded, setIsLoaded] = React.useState<boolean>(false);
	const [mode, setMode] = React.useState<MODE>(prefersDarkMode() ? MODE.DARK : MODE.LIGHT);
	const history = useHistory();

	if (!glSupported()) return <div>'WebGL is not supported on this device.'</div>;

	const updateModeFromSystemPreference = (e) => {
		setMode(e.matches ? MODE.DARK : MODE.LIGHT);
	};

	React.useEffect(() => {
		setTimeout(() => {
			setIsLoaded(true);
		}, 1000);

		const prefersDark = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)');
		if (prefersDark.addEventListener) {
			prefersDark.addEventListener('change', updateModeFromSystemPreference);
		}
		return () => {
			if (prefersDark.removeEventListener) {
				prefersDark.removeEventListener('change', updateModeFromSystemPreference);
			}
		};
	}, []);

	React.useEffect(() => {
		if (section === slugForIndex(activePageIndex)) return;
		setActivePageIndex(indexForSection(section));
	}, [section]);

	React.useEffect(() => {
		setTimeout(() => {
			history.push(slugForIndex(activePageIndex));
		}, 0);
	}, [activePageIndex]);

	return (
		<div className={cx(styles.app, mode === MODE.DARK && styles.dark)}>
			<Loader mode={mode} isLoaded={isLoaded} />

			<Navigation activePageIndex={activePageIndex} setActivePageIndex={setActivePageIndex} isTouch={isTouch} mode={mode} isLoaded={isLoaded} />
			<div className={cx(styles.toggles, isLoaded && styles.loaded)}>
				<a
					className={cx(styles.toggle, mode === MODE.DARK && styles.active)}
					onClick={() => {
						setMode(MODE.DARK);
					}}>
					Dark
				</a>
				<a
					className={cx(styles.toggle, mode === MODE.LIGHT && styles.active)}
					onClick={() => {
						setMode(MODE.LIGHT);
					}}>
					Light
				</a>
			</div>
			{isLoaded && (
				<Water numItems={projects.length + 1} activePageIndex={activePageIndex} setActivePageIndex={setActivePageIndex} mode={mode} isTouch={isTouch} isLoaded={isLoaded}>
					<Content items={projects} mode={mode} />
				</Water>
			)}
		</div>
	);
};

export default App;
