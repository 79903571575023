import * as React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter as Router, Switch, Route, useParams } from 'react-router-dom';
import { AppContainer, setConfig } from 'react-hot-loader';
import App from './components/App';

setConfig({
	errorReporter: () => null,
	ErrorOverlay: () => null,
});

const rootEl = document.getElementById('root');

const Section = () => {
	const { slug } = useParams();
	return <App section={slug} />;
};

const RoutedApp = () => {
	return (
		<Router>
			<Switch>
				<Route exact path='/'>
					<App />
				</Route>
				<Route path='/:slug'>
					<Section />
				</Route>
			</Switch>
		</Router>
	);
};

ReactDOM.render(
	<AppContainer>
		<RoutedApp />
	</AppContainer>,
	rootEl
);
