const ABOUT = {
	title:
		'Creative Coding / Software Engineering / Data Visualization / Installation Art / Design',
	email: 'annekagoss at gmail dot com',
	twitter: 'https://twitter.com/anneka_gss',
	instagram: 'https://www.instagram.com/geometricprimitive',
	github: 'https://github.com/annekagoss',
	medium: 'https://annekagoss.medium.com/',
	vimeo: 'https://vimeo.com/geometricprimitive',
	behance: 'https://www.behance.net/annekagoss',
	shadertoy: 'https://www.shadertoy.com/user/anneka',
	text: 'Resume upon request.',
};

export default ABOUT;
