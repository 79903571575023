exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".container__2P3jy{position:absolute;top:0;left:0;right:0;bottom:0;height:100vh;opacity:0}.container__2P3jy.loaded__cZxEE{opacity:1}.fullScreenCanvas__2ToDf{width:100vw;height:50vh;z-index:1;pointer-events:none;position:fixed;overflow-y:scroll;bottom:0;border:none}.canvasContainer__3oDRc{position:relative;height:100%;width:100%}.canvasForeground__3Fitd{position:absolute;top:0;left:0;right:0}button{cursor:pointer}input{outline:none}\n", "",{"version":3,"sources":["WaterCanvas.module.scss"],"names":[],"mappings":"AAAA,kBAAW,iBAAiB,CAAC,KAAK,CAAC,MAAM,CAAC,OAAO,CAAC,QAAQ,CAAC,YAAY,CAAC,SAAS,CAAC,gCAAkB,SAAS,CAAC,yBAAkB,WAAW,CAAC,WAAW,CAAC,SAAS,CAAC,mBAAmB,CAAC,cAAc,CAAC,iBAAiB,CAAC,QAAQ,CAAC,WAAW,CAAC,wBAAiB,iBAAiB,CAAC,WAAW,CAAC,UAAU,CAAC,yBAAkB,iBAAiB,CAAC,KAAK,CAAC,MAAM,CAAC,OAAO,CAAC,OAAO,cAAc,CAAC,MAAM,YAAY","file":"WaterCanvas.module.scss","sourcesContent":[".container{position:absolute;top:0;left:0;right:0;bottom:0;height:100vh;opacity:0}.container.loaded{opacity:1}.fullScreenCanvas{width:100vw;height:50vh;z-index:1;pointer-events:none;position:fixed;overflow-y:scroll;bottom:0;border:none}.canvasContainer{position:relative;height:100%;width:100%}.canvasForeground{position:absolute;top:0;left:0;right:0}button{cursor:pointer}input{outline:none}\n"]}]);

// Exports
exports.locals = {
	"container": "container__2P3jy",
	"loaded": "loaded__cZxEE",
	"fullScreenCanvas": "fullScreenCanvas__2ToDf",
	"canvasContainer": "canvasContainer__3oDRc",
	"canvasForeground": "canvasForeground__3Fitd"
};